.sidebar h1.title
   text-transform: uppercase

ul.categories li
   font-size: 1.3rem
   padding: .5rem 0
   cursor: pointer
   transition: .2s ease-in
   &:hover
      color: var(--txtColor)

@media screen and (max-width: 830px)
   h1.title
      font-size: 1.3rem
      font-weight: 500
      margin-bottom: 1rem
      text-transform: uppercase
   ul.categories li
      font-size: 1.2rem
      padding: 0 0 .5rem 1rem
      &:last-child
         padding-bottom: 0
         