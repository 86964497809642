#goTop
	display: none
	position: fixed
	right: 2rem
	bottom: 3rem
	z-index: 96
	padding: .15rem .5rem
	background-color: var(--hColor)
	fill: var(--bg)
	border-radius: 2rem
	opacity: .6
	cursor: pointer
	transition: .2s ease-in
	&:hover
		opacity: 1
	
	svg
		width: 1.2rem
		
.d-block
	display: block !important