:root
	--bg: #FEFEFE
	--bg-2: #F8F8F8
	--hColor: #2D2D2D
	--txtColor: #6A6A6A
	--fs: 1.05rem
	& body.dark
		--bg: #212121
		--bg-2: #2D2D2D
		--hColor: #FBFBFB
		--txtColor: #C6C6C6

::-webkit-scrollbar
	width: .7rem
::-webkit-scrollbar-track
	background: transparent
::-webkit-scrollbar-thumb
	background: rgba(0,0,0,.2)
	border-radius: 1rem

*
	margin: 0
	padding: 0
	border: 0
	outline: none
	list-style: none

html
	overflow-x: hidden
body
	background: var(--bg-2)
	color: var(--txtColor)
	font-family: 'Open Sans', sans-serif
	font-weight: 300
	font-size: var(--fs)
	transition: .2s ease-in
	overflow-x: hidden
h1, h2
	color: var(--hColor)
h1
	font-size: 2rem
	margin-bottom: 2rem
	font-weight: 300
h2
	font-size: 1.5rem
	margin-bottom: .8rem
a
	color: var(--hColor)
	font-weight: 400
	text-decoration: none
	transition: .2s ease-in
	&:hover
		color: var(--txtColor)
button
	background: none

@media (max-width: 58.6em)
	body
		font-size: 1.22rem