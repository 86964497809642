@use '../../sass/variables' as *

header
   position: relative 
   @include flexBetweenCenter
   padding: .8rem 3rem
   background-color: var(--bg)
   z-index: 98
   @include shadow

   a.logo
      line-height: 0
      img
         width: 10rem
         height: 3.2rem

   .navbar
      @include flexBetweenCenter

   .nav-btns
      display: flex
      justify-content: space-between
      align-items: center

   #toggle-theme
      cursor: pointer
      img
         width: 1.5rem

   nav ul
      @include flexBetweenCenter
      li
         padding: 0 1.5rem
         a
            font-weight: 300
.dark
   header
      .logo
         filter: invert(1)
   
@media screen and (max-width: 830px)
   header
      padding: 1rem
      #toggle-theme
         margin-right: 1rem
         position: relative
         z-index: 9999
      .nav-toggle
         position: relative
         display: grid
         gap: .5rem
         width: 1.8rem
         z-index: 999
         transition: .2s ease-in
         cursor: pointer
         .bar1,
         .bar2,
         .bar3
            display: block
            width: 100%
            height: .11rem
            background: var(--hColor)
            transition: .2s ease-in
            cursor: pointer
         &[aria-expanded="true"]
            right: -.5rem
            .bar1
               transform: rotate(-45deg) translateX(-50%)
            .bar2
               opacity: 0
            .bar3
               transform: rotate(45deg) translateX(-50%)
            
      #navMobileOpen
         position: absolute
         inset: 0
         width: 100vw
         height: 100vh
         z-index: 99
         transform: translateX(100%)
         transform-origin: right
         transition: transform .6s ease-in,-webkit-transform .6s ease-in
         .navMobilePannel
            position: absolute
            inset: 0
            z-index: 99
            padding: 6rem 2rem
            background-color: var(--bg)
            overflow: auto
            @include shadow
            nav ul
               display: block
               li
                  padding: 0 0 .8rem 0
                  &:last-child
                     padding-bottom: 2rem
                  
         &[data-visible="true"]
            transform: translateX(0) !important