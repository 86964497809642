@use './global'
@use './variables' as *

$wSidebar: 16.5rem
@mixin paddContainer
	padding: 4rem 2rem

main
	height: calc(100vh - 4.5rem)
	
	.sidebar
		position: relative
		width: calc($wSidebar - 4rem)
		@include hSize
		@include paddContainer
		float: left
		background-color: var(--bg)
		color: var(--hColor)
		@include shadow
		transition: transform .5s ease-in-out

		&[data-visible="false"]
			position: absolute
			transform: translateX(-100%)

	button#sidebar-toggle
		display: block
		position: absolute
		top: 1.2rem
		right: 1.5rem
		padding: .8rem
		background-image: url(../img/arrow.svg)
		background-repeat: no-repeat
		background-position: center
		transform: rotate(180deg)
		
		&[aria-expanded="false"]
			left: 1.5rem
			right: unset
			transform: rotate(0)

	.container
		position: relative
		width: calc(100% - $wSidebar - 4rem)
		@include hSize
		@include paddContainer
		float: right
		transition: width .5s ease-in-out
		
		&[data-full-width="false"]
			width: calc(100% - 8rem)


	footer
		@include flexBetweenCenter
		justify-content: center
		padding-top: 3rem
		text-align: center

	
	@media screen and (max-width: 830px)
		.container
			width: calc(100% - 3.5rem)
			padding-left: 1.5rem
			margin-right: 0

body.dark
	main
		button#sidebar-toggle
			background-image: url(../img/arrow-w.svg)

.flex
	display: flex
	justify-content: flex-start
	align-items: center
	gap: 2rem
	flex-wrap: wrap

	@media screen and (max-width: 830px)
		flex-direction: column
		align-items: start
		gap: 1rem
