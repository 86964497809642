@use '../../sass/variables' as *

.pagination
   width: 100%
   display: flex
   justify-content: center
   align-items: center
   flex-wrap: wrap
   gap: .8rem
   margin-top: 2rem

   button
      font-family: 'Open Sans', sans-serif
      padding: .4rem .8rem
      border-radius: 1rem
      font-size: 1rem
      color: var(--txtColor)
      transition: .5s ease

      &:hover
         background: var(--bg)
      
      &.active
         background: var(--bg)