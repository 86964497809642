@use '../../sass/variables' as *

.activeCat
   font-size: 3.6rem
   margin-bottom: 1rem
   animation: fadeIn 1s ease-in
   &::first-letter
      text-transform: uppercase

select
   min-width: 4rem
   padding: .5rem .8rem
   margin-left: .6rem
   background-color: var(--bg)
   color: var(--txtColor)
   border-radius: 1rem
   @include shadow

form
   display: flex
   gap: .8rem
   input
      width: 13rem
   input,
   button
      padding: .5rem .8rem
      border-radius: 1rem
      color: var(--hColor)
      background: var(--bg)
      @include shadow
   button
      padding-inline: 2rem
      &:hover
         color: var(--bg)
         background: var(--hColor)
         transition: .4s ease-in


@media screen and (max-width: 830px)
   .activeCat
      font-size: 2.3rem
   select
      margin-top: .5rem
   form
      flex-direction: column