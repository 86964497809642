@use '../../sass/variables' as *

.card-container
   display: flex
   justify-content: flex-start
   align-items: flex-start
   flex-wrap: wrap
   gap: 4rem
   margin-top: 3rem
   min-height: 65vh

   .card
      width: calc(100% / 3 - 3rem)
      min-width: 18rem
      margin-bottom: 2rem
      background-color: var(--bg)
      color: var(--txtColor)
      @include shadow
      overflow: hidden
      animation: fadeIn 1s ease-in
      .browser-bar
         display: block
         height: 1.5rem
         background: #e1e3e6 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 25'%3E%3Ccircle fill='red' cx='3.6' cy='12.8' r='3.5'/%3E%3Ccircle fill='orange' cx='16.6' cy='12.8' r='3.5'/%3E%3Ccircle fill='green' cx='29.6' cy='12.8' r='3.5'/%3E%3C/svg%3E") no-repeat .8rem 0
         background-size: cover

      img
         width: 100%
         aspect-ratio: 14.3 / 9
         &::before
            content: 'Broken Image: ' attr(alt)
            display: flex
            justify-content: center
            align-items: center
            text-align: center
            background-color: #ececec
            color: #212121
            aspect-ratio: 14.3 / 9

      .card-info
         padding: 1.6rem

         a
            text-decoration: none
            
            h2
               color: var(--hColor)

   
      @media screen and (max-width: 830px)
         width: 100%
         .card-info
            padding: 1.2rem

@keyframes fadeIn
   0%
      opacity: 0
      transform: rotateY(-2deg) rotateX(-2deg)
   100%
      opacity: 1
      transform: rotateY(0deg) rotateX(0deg)